// Dashboard Layout JS

// Dashbord Configuration Options

	var body = jQuery('body');
	var html = jQuery('html');

	function dashboardSettings({
		version,
		layout,
		navheaderBg,
		headerBg,
		sidebarStyle,
		sidebarBg,
		sidebarPosition,
		headerPosition,
		containerLayout,
		direction,
		primary
	}) {
		this.version = version || "light";
		this.layout = layout || "vertical";
		this.navheaderBg = navheaderBg || "color_1";
		this.headerBg = headerBg || "color_1";
		this.sidebarStyle = sidebarStyle || "full";
		this.sidebarBg = sidebarBg || "color_1";
		this.sidebarPosition = sidebarPosition || "static";
		this.headerPosition = headerPosition || "static";
		this.containerLayout = containerLayout || "wide";
		this.primary = primary || "color_1";
		this.manageVersion();
		this.manageLayout();
		this.manageNavHeaderBg();
		this.manageHeaderBg();
		this.manageSidebarStyle();
		this.manageSidebarBg();
		this.manageSidebarPosition();
		this.manageHeaderPosition();
		this.manageContainerLayout();
		this.manageResponsiveSidebar();
		this.managePrimaryColor();
	}
	dashboardSettings.prototype.manageVersion = function () {
		switch (this.version) {
			case "light":
				body.attr("data-theme-version", "light");
				break;
			case "dark":
				body.attr("data-theme-version", "dark");
				break;
			default:
				body.attr("data-theme-version", "light");
		}
	}
	dashboardSettings.prototype.manageLayout = function () {
		switch (this.layout) {
			case "horizontal":
				this.sidebarStyle === "overlay" ? body.attr("data-sidebar-style", "full") : body.attr("data-sidebar-style", `jQuery{this.sidebarStyle}`);
				body.attr("data-layout", "horizontal");
				break;
			case "vertical":
				body.attr("data-layout", "vertical");
				break;
			default:
				body.attr("data-layout", "vertical");
		}
	}
	dashboardSettings.prototype.manageNavHeaderBg = function () {
		switch (this.navheaderBg) {
			case "color_1":
				body.attr("data-nav-headerbg", "color_1");
				break;
			case "color_2":
				body.attr("data-nav-headerbg", "color_2");
				break;
			case "color_3":
				body.attr("data-nav-headerbg", "color_3");
				break;
			case "color_4":
				body.attr("data-nav-headerbg", "color_4");
				break;
			case "color_5":
				body.attr("data-nav-headerbg", "color_5");
				break;
			case "color_6":
				body.attr("data-nav-headerbg", "color_6");
				break;
			case "color_7":
				body.attr("data-nav-headerbg", "color_7");
				break;
			case "color_8":
				body.attr("data-nav-headerbg", "color_8");
				break;
			case "color_9":
				body.attr("data-nav-headerbg", "color_9");
				break;
			case "color_10":
				body.attr("data-nav-headerbg", "color_10");
				break;
			case "color_11":
				body.attr("data-nav-headerbg", "color_11");
				break;
			case "color_12":
				body.attr("data-nav-headerbg", "color_12");
				break;
			case "color_13":
				body.attr("data-nav-headerbg", "color_13");
				break;
			case "color_14":
				body.attr("data-nav-headerbg", "color_14");
				break;
			case "color_15":
				body.attr("data-nav-headerbg", "color_15");
				break;
			default:
				body.attr("data-nav-headerbg", "color_1");
		}
	}
	dashboardSettings.prototype.manageHeaderBg = function () {
		switch (this.headerBg) {
			case "color_1":
				body.attr("data-headerbg", "color_1");
				break;
			case "color_2":
				body.attr("data-headerbg", "color_2");
				break;
			case "color_3":
				body.attr("data-headerbg", "color_3");
				break;
			case "color_4":
				body.attr("data-headerbg", "color_4");
				break;
			case "color_5":
				body.attr("data-headerbg", "color_5");
				break;
			case "color_6":
				body.attr("data-headerbg", "color_6");
				break;
			case "color_7":
				body.attr("data-headerbg", "color_7");
				break;
			case "color_8":
				body.attr("data-headerbg", "color_8");
				break;
			case "color_9":
				body.attr("data-headerbg", "color_9");
				break;
			case "color_10":
				body.attr("data-headerbg", "color_10");
				break;
			case "color_11":
				body.attr("data-headerbg", "color_11");
				break;
			case "color_12":
				body.attr("data-headerbg", "color_12");
				break;
			case "color_13":
				body.attr("data-headerbg", "color_13");
				break;
			case "color_14":
				body.attr("data-headerbg", "color_14");
				break;
			case "color_15":
				body.attr("data-headerbg", "color_15");
				break;
			case "transparent":
				body.attr("data-headerbg", "transparent");
				break;
			default:
				body.attr("data-headerbg", "color_1");
		}
	}
	dashboardSettings.prototype.manageSidebarStyle = function () {

		switch (this.sidebarStyle) {
			case "full":
				body.attr("data-sidebar-style", "full");
				break;
			case "mini":
				body.attr("data-sidebar-style", "mini");
				break;
			case "compact":
				body.attr("data-sidebar-style", "compact");
				break;
			case "modern":
				body.attr("data-sidebar-style", "modern");
				break;
			case "icon-hover":
				body.attr("data-sidebar-style", "icon-hover");

				jQuery('.dlabnav').hover(function () {
					jQuery('#main-wrapper').addClass('iconhover-toggle');
				}, function () {
					jQuery('#main-wrapper').removeClass('iconhover-toggle');
				});

				jQuery('.dlabnav').hover(function () {
					jQuery('#main-wrapper').addClass('iconhover-toggle');
				}, function () {
					jQuery('#main-wrapper').removeClass('iconhover-toggle');
				});
				break;
			case "overlay":
				this.layout === "horizontal" ? body.attr("data-sidebar-style", "full") : body.attr("data-sidebar-style", "overlay");
				break;
			default:
				body.attr("data-sidebar-style", "full");
		}
	}
	dashboardSettings.prototype.manageSidebarBg = function () {
		switch (this.sidebarBg) {
			case "color_1":
				body.attr("data-sidebarbg", "color_1");
				break;
			case "color_2":
				body.attr("data-sidebarbg", "color_2");
				break;
			case "color_3":
				body.attr("data-sidebarbg", "color_3");
				break;
			case "color_4":
				body.attr("data-sidebarbg", "color_4");
				break;
			case "color_5":
				body.attr("data-sidebarbg", "color_5");
				break;
			case "color_6":
				body.attr("data-sidebarbg", "color_6");
				break;
			case "color_7":
				body.attr("data-sidebarbg", "color_7");
				break;
			case "color_8":
				body.attr("data-sidebarbg", "color_8");
				break;
			case "color_9":
				body.attr("data-sidebarbg", "color_9");
				break;
			case "color_10":
				body.attr("data-sidebarbg", "color_10");
				break;
			case "color_11":
				body.attr("data-sidebarbg", "color_11");
				break;
			case "color_12":
				body.attr("data-sidebarbg", "color_12");
				break;
			case "color_13":
				body.attr("data-sidebarbg", "color_13");
				break;
			case "color_14":
				body.attr("data-sidebarbg", "color_14");
				break;
			case "color_15":
				body.attr("data-sidebarbg", "color_15");
				break;
			default:
				body.attr("data-sidebarbg", "color_1");
		}
	}
	dashboardSettings.prototype.manageSidebarPosition = function () {
		switch (this.sidebarPosition) {
			case "fixed":
				this.sidebarStyle === "overlay" && this.layout === "vertical" || this.sidebarStyle === "modern" ? body.attr("data-sidebar-position", "static") : body.attr("data-sidebar-position", "fixed");
				break;
			case "static":
				body.attr("data-sidebar-position", "static");
				break;
			default:
				body.attr("data-sidebar-position", "static");
		}
	}
	dashboardSettings.prototype.manageHeaderPosition = function () {
		switch (this.headerPosition) {
			case "fixed":
				body.attr("data-header-position", "fixed");
				break;
			case "static":
				body.attr("data-header-position", "static");
				break;
			default:
				body.attr("data-header-position", "static");
		}
	}
	dashboardSettings.prototype.manageContainerLayout = function () {
		switch (this.containerLayout) {
			case "boxed":
				if (this.layout === "vertical" && this.sidebarStyle === "full") {
					body.attr("data-sidebar-style", "overlay");
				}
				body.attr("data-container", "boxed");
				break;
			case "wide":
				body.attr("data-container", "wide");
				break;
			case "wide-boxed":
				body.attr("data-container", "wide-boxed");
				break;
			default:
				body.attr("data-container", "wide");
		}
	}
	dashboardSettings.prototype.manageResponsiveSidebar = function () {
		const innerWidth = jQuery(window).innerWidth();
		if (innerWidth < 1300) {
			body.attr("data-layout", "vertical");
			body.attr("data-container", "wide");
		}

		if (innerWidth > 767 && innerWidth < 1000) {
			body.attr("data-sidebar-style", "overlay");
		}

		if (innerWidth < 768) {
			body.attr("data-sidebar-style", "overlay");
		}
	}
	dashboardSettings.prototype.managePrimaryColor = function () {
		switch (this.primary) {
			case "color_1":
				body.attr("data-primary", "color_1");
				break;
			case "color_2":
				body.attr("data-primary", "color_2");
				break;
			case "color_3":
				body.attr("data-primary", "color_3");
				break;
			case "color_4":
				body.attr("data-primary", "color_4");
				break;
			case "color_5":
				body.attr("data-primary", "color_5");
				break;
			case "color_6":
				body.attr("data-primary", "color_6");
				break;
			case "color_7":
				body.attr("data-primary", "color_7");
				break;
			case "color_8":
				body.attr("data-primary", "color_8");
				break;
			case "color_9":
				body.attr("data-primary", "color_9");
				break;
			case "color_10":
				body.attr("data-primary", "color_10");
				break;
			case "color_11":
				body.attr("data-primary", "color_11");
				break;
			case "color_12":
				body.attr("data-primary", "color_12");
				break;
			case "color_13":
				body.attr("data-primary", "color_13");
				break;
			case "color_14":
				body.attr("data-primary", "color_14");
				break;
			case "color_15":
				body.attr("data-primary", "color_15");
				break;

			default:
				body.attr("data-primary", "color_1");
		}
	}

// Dashboard Functions

	var dashboard = function () {

		// Set minimum page content height

		var handlePageContentMinHeight = function () {
			var win_h = window.outerHeight;
			if (win_h > 0 ? win_h : screen.height) {
				jQuery(".content-body").css("min-height", (win_h + 60) + "px");
			};
		}
		
		// Configure Dashboard Navigation Menu
		
		var handleDashboardMenu = function () {
			if (jQuery('#menu').length > 0) {
				jQuery("#menu").metisMenu();
			}
			jQuery('.metismenu > .mm-active ').each(function () {
				if (!jQuery(this).children('ul').length > 0) {
					jQuery(this).addClass('active-no-child');
				}
			});
		}

		// If the current page URL is a link in the Dashboard Navigation Menu, then open the menu item that contains it

			
		var handleDashboardMenuCurrentActive = function () {
			for (var nk = window.location,
					o = jQuery("ul#menu a").filter(function () {
						return this.href == nk;
					})
					.addClass("mm-active")
					.parent()
					.addClass("mm-active");;) {
				if (!o.is("li")) break;
				o = o.parent()
					.addClass("mm-show")
					.parent()
					.addClass("mm-active");
			}
		}

		// Scroll Dashboard Navigation Content

		var handleDashboardNavigationScroll = function () {
			if (jQuery('.dlabnav-scroll').length > 0) {
				
				const dnsb = new PerfectScrollbar('.dlabnav-scroll');
				
				jQuery(".nav-control").on('click', function () {
					dnsb.update();
				});
				
				jQuery(window).on('resize', function () {
					dnsb.update();
				});
				
			}
		}
		
		// When the Sidebar Style is set to mini

		var handleSidebarStyleMini = function () {
			jQuery("ul#menu>li").on('click', function () {
				const sidebarStyle = jQuery('body').attr('data-sidebar-style');
				if (sidebarStyle === 'mini') {
					jQuery(this).find('ul').stop()
				}
			})
		}
		
		// Toogle Dashboard Navigation

		var handleDashboardNavigation = function () {
			jQuery(".nav-control").on('click', function () {
				jQuery('#main-wrapper').toggleClass("menu-toggle");
				jQuery(".hamburger").toggleClass("is-active");
			});
		}
		
		return {
			init: function () {
				handlePageContentMinHeight();
				handleDashboardNavigationScroll();
				handleDashboardMenu();
				handleDashboardMenuCurrentActive();
				handleSidebarStyleMini();
				handleDashboardNavigation();
			},

			load: function () {
				
			},

			resize: function () {
				handleDashboardNavigationScroll();
			}
		}

	}();

// Dashboard Configuration

	var dashboardSettingsOptions = {
		version: "light",			// version: light, dark	
		layout: "vertical",			// layout: horizontal, vertical
		primary: "color_1",			// primary: color_1, ..., color_15
		headerBg: "color_1",		// headerBg: color_1, ..., color_15, transparent
		navheaderBg: "color_1",		// navheaderBg: color_1, ..., color_15
		sidebarBg: "color_1",		// sidebarBg: color_1, ..., color_15
		sidebarStyle: "full",		// sidebarStyle: full, mini, compact, modern, icon-hover, overlay
		sidebarPosition: "fixed",	// sidebarPosition: fixed, static
		headerPosition: "fixed",	// headerPosition: fixed, static
		containerLayout: "wide",	// containerLayout: boxed, wide, wide-boxed, wide
	};

// Dashboard Start

	jQuery(document).ready(function () {
		
		dashboard.init();
		
	});

	jQuery(window).on('load', function () {
		
		new dashboardSettings(dashboardSettingsOptions);

		dashboard.load();
		
	});

	jQuery(window).on('resize', function () {
		
		dashboard.resize();
		
		// Check container layout on resize
		dashboardSettingsOptions.containerLayout = jQuery('#container_layout').val();
		new dashboardSettings(dashboardSettingsOptions); 
		
	});